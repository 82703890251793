import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`30:00 Running Clock`}</p>
    <p>{`15 Calorie Bike`}</p>
    <p>{`15 Box Jumps/Step Ups (24/20)`}</p>
    <p>{`15 Bent Leg Bridge`}</p>
    <p>{`1:00 Plank`}</p>
    <p>{`200M Run`}</p>
    <p>{`then,`}</p>
    <p>{`Hip, Quad, Hamstring mobility work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      